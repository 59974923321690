<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="title-product__availability">
        {{ $t('voucher.voucher') }}
      </div>
      <div class="ml-auto d-flex">
        <router-link
          :to="to(`/promotion-channel/${$route.params.id}/voucher/add`)"
          class="adjustment__button d-flex align-items-center h-30px p-2"
          style="font-size: 12px; border-radius: 3px"
        >
          <a-icon type="plus-square" class="mr-2" style="font-size:20px" />
          {{ $t('voucher.createvoucher') }}
        </router-link>
      </div>
    </div>

    <a-row :gutter="24" class="my-3">
      <a-col :span="12">
        <label class="mb-1">&nbsp;</label>
        <div class="d-flex align-items-center">
          <a-input class="w-100" @change="handleSearch" />
          <a-button type="danger" icon="search">
            {{ $t('productAvailability.search') }}
          </a-button>
        </div>
      </a-col>
      <a-col :span="6" :offset="2">
        <label class="mb-1">{{ $t('discount.period') }}</label>
        <a-range-picker v-model="dates" format="DD MMM YYYY" @change="handleDate" />
      </a-col>
      <a-col :span="4">
        <label class="mb-1">{{ $t('order.sortBy') }}</label>
        <a-select
          :value="$route.query.order || 'DESC'"
          :placeholder="'Urutkan'"
          class="w-100"
          allow-clear
          @select="handleSelect"
        >
          <a-select-option
            v-for="(item) in sorting_optional"
            :key="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  
    <div class="product-availability__utils">
      <a-tabs
        :default-active-key="$route.query.status || 'ALL'"
        @change="handleStatus"
      >
        <a-tab-pane key="ALL" :tab="$t('voucher.allstatus')" />
        <a-tab-pane key="WAITING_APPROVAL" :tab="$t('voucher.waiting')" />
        <a-tab-pane key="ACTIVE" :tab="$t('voucher.active')" />
        <a-tab-pane key="INACTIVE" :tab="$t('voucher.nonactive')" />
      </a-tabs>
    </div>

    <TableBorder>
      <template #thead>
        <th v-for="th in t_head" :key="th" class="h-48px" scope="col">
          <div v-if="th === 'checkbox'"> 
            <a-checkbox :disabled="voucher_list.length < 1" />
          </div>
          <div v-else-if="th === 'action'"> 
            {{ '' }}
          </div>
          <div v-else>
            {{ $t('store_list_page.' + th) }}
          </div>
        </th>
      </template>
      <tbody>
        <tr v-for="val in voucher_list" :key="val.id">
          <td>
            <a-checkbox />
          </td>
          <td>
            <div class="program">
              {{ val.code }}
            </div>
            <div class="type">
              {{ val.type }}
            </div>
          </td>
          <td>
            {{ val.promotion_name }}
          </td>
          <td>
            {{ [ val.start_date && $moment(val.start_date.replace(' ','')).format('DD MMM YYYY HH:mm'), val.end_date && $moment(val.end_date.replace(' ','')).format('DD MMM YYYY HH:mm') ].filter(Boolean).join(' - ') }}
          </td>
          <td>
            {{ val.quota }}
          </td>
          <td>
            <a-tag :color="val.status === 'WAITING_APPROVAL' ? 'orange' : val.status === 'ACTIVE' ? 'blue' : 'red'">
              {{ $t(`discount.${val.status.toLowerCase()}`) }}
            </a-tag>
            <a-popover v-if="val.status === 'INACTIVE' && val.reason" title="Alasan Pembatalan" style="font-size: 14px; cursor: pointer">
              <template slot="content">
                {{ val.reason }}
              </template>
              <a-icon type="info-circle" style="color: #FF0A54" />
            </a-popover>
          </td>
          <td>
            <a-dropdown
              v-if="permission.includes('READ') || permission.includes('WRITE')"
              style="float: right"
              :overlay-style="{ width: '150px' }"
            >
              <a @click.prevent="">
                <a-icon type="more" />
              </a>

              <template #overlay>
                <a-menu>
                  <a-menu-item v-if="val.status === 'WAITING_APPROVAL' && permission.includes('WRITE')" key="1" class="py-2" @click="toEditVoucher(val.id)">
                    <a-icon type="edit" />
                    {{ $t('voucher.edit') }}
                  </a-menu-item>
                  <a-menu-item key="2" class="py-2" @click="toEditVoucher(val.id, 'detail')">
                    <a-icon type="eye" />
                    {{ $t('store_list_page.view_detail') }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
        </tr>
      </tbody>
    </TableBorder>

    <EmptyInfo
      v-if="!voucher_list.length"
      class="py-4"
      :title="$t('utils.no_data')"
    >
      <router-link
        :to="to(`/promotion-channel/${$route.params.id}/voucher/add`)"
        class="ant-btn ant-btn-lg ant-btn-danger px-4 mt-4"
      >
        {{ $t('voucher.createvoucher') }}
      </router-link>
    </EmptyInfo>

    <Pagination
      v-if="voucher_list.length"
      class="text-right"
      :total="pagination.total_items"
      :page-size="pagination.size"
      :total-length-per-page="voucher_list.length"
      :default-page="page"
      @changePage="changePage"
    />
  </div>
</template>

<script>
import voucher from './composable/voucher'
import TableBorder from '@/components/Table/TableBorder.vue'
import Pagination from '@/components/Pagination/index.vue'
import EmptyInfo from '@/components/EmptyInfo'
import useQueryParam from '@/composable/useQueryParam'

export default {
  name: 'Voucher',
  components: {
    TableBorder,
    Pagination,
    EmptyInfo,
  },
  setup() {
    const {
      t_head, voucher_list, page, pagination, changePage, handleSearch, sorting_optional, 
      dates, handleSelect, handleStatus, handleDate, toEditVoucher, permission,
    } = voucher()
    const { to } = useQueryParam()

    return {
      to,
      page,
      pagination,
      t_head,
      voucher_list,
      changePage,
      handleSearch,
      sorting_optional,
      dates,
      handleSelect,
      handleStatus,
      handleDate,
      toEditVoucher,
      permission,
    }
  },
}
</script>

<style lang="scss">
.title-product__availability {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 26px;
  color: #1c1c1c;
}

// .search__button {
//   color: #fff !important;
//   background:#2196F3 !important;
// }

.inbound__button {
  color: #FF0854 !important;
  background:#FFF !important;
  border-color:#FF0854 !important;
}
.adjustment__button {
  color: #fff !important;
  background:#FF0854 !important;
}

.program {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #2196F3;
}

.type {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #495057
}
</style>